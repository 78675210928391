import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbMail } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import { GrInstagram } from "react-icons/gr";
import { LuFacebook } from "react-icons/lu";
import { RxTwitterLogo } from "react-icons/rx";
import { FiLinkedin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import logoSVG from '../../src/Assets/images/qulabs-logo.svg';

export default function Footer (props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  const navigate = useNavigate();
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@qulabs.ai';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+916281684342';
  };

  const openInstagramProfile = () => {
    window.open('https://www.instagram.com/qulabssoftware/', '_blank');
  };

   const openTwitterProfile = () => {
    window.open('https://twitter.com/i/flow/login?redirect_after_login=%2Fqulabssoftware', '_blank');
  };

  const openLinkedInProfile = () => {
    window.open('https://www.linkedin.com/company/qulabs-software-india/', '_blank');
  };
  return (
    <div className={`flex flex-col items-center justify-center w-full ${props.showpage ? 'md:h-[27rem] h-auto pt-10 pb-1' : 'md:h-[13.5rem] h-auto'} bg-[#051525] gap-4 mt-5 `}>
      {
        props.showpage &&(
          <div className="flex flex-col  gap-4 w-[75%] border-2 border-[#1B99D4] px-[2.25rem] py-[1.25rem] rounded-3xl">
        <div className="text-center text-[#E6FCFF] font-opensans font-semibold 2xl:text-2xl md:text-xl text-md">
          Where can you find us? 
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center md:gap-12 gap-6">
          <a  href="https://www.google.com/maps/search/USA+Qulabz+Inc+16192+Coastal+Highway+Lewes+Delaware,+19958/@38.782478,-75.2334244,12z/data=!3m1!4b1?entry=ttu"
            target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 md:w-[24.3rem] md:h-[5.37rem] w-[16rem] h-[4rem] bg-[#0C2E52] hover:bg-[#0C2E52]/30 rounded-2xl px-[1.875rem] py-[0.625rem]">
            <HiOutlineLocationMarker size={24} color="#1B99D4" />
            <div className="flex flex-wrap sm:w-[18rem] w-[90%] text-[#D9FBFF] opacity-50 font-opensans 2xl:text-sm md:text-[0.7rem] text-[0.6rem] font-normal ">
              USA Qulabz Inc 16192 Coastal Highway Lewes Delaware, 19958
            </div>
          </a>
          <a  href="https://www.google.com/maps/place/Qulabs+Software+(India)+Pvt.+Ltd./@17.426882,78.4427399,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb91d51fc46755:0xe39c60f435e2b5dc!8m2!3d17.4268769!4d78.4453148!16s%2Fg%2F11jzpq2lsc?entry=ttu"
            target="_blank" rel="noreferrer" className="flex items-center justify-center gap-3 md:w-[24.3rem] md:h-[5.37rem] w-[16rem] h-[4rem] bg-[#0C2E52] hover:bg-[#0C2E52]/30 rounded-2xl px-[1.875rem] py-[0.625rem]">
            <HiOutlineLocationMarker size={24} color="#1B99D4" />
            <div className="flex flex-wrap sm:w-[18rem] w-[90%] text-[#D9FBFF] opacity-50 font-opensans 2xl:text-sm md:text-[0.7rem] text-[0.6rem]  font-normal ">
              Qulabs Software (India) Pvt Ltd 8-2-351/N/1, 4th floor,
              Banjara Hills Road No.3, Hyderabad-500034,Telangana
            </div>
          </a>
        </div>
      </div>
        )
      }
      <div className="md:w-[66.1rem] md:h-[9.5rem] w-[80%] flex flex-row md:gap-12 gap-10 items-center justify-between">
        <div className="text-white md:w-[11rem] md:h-[9rem] w-[9rem] h-[8rem] font-opensans ">
        <img className="md:w-[11rem] md:h-[9rem] w-[9rem] h-[8rem] " src={logoSVG} alt="Qulabs Logo" />
        </div>
        <div className="flex md:flex-row flex-col md:gap-9 font-opensans">
        <div className="flex md:gap-9 gap-2 items-start justify-center">
          <div className="p-[0.625rem]">
           
            <div className="text-[#E6FCFF]  font-semibold 2xl:text-xl md:text-lg text-sm leading-loose mb-1.5">Product</div>
            <ul className="text-[#B1F6FF]   2xl:text-sm text-[0.6rem] ">
              <li onClick={() => {
                 scrollToTop();
                navigate("/Ourip")
              }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">Our IP's</li>
              <li  onClick={() => {
                 scrollToTop();
                    navigate("/Hardware")
                  }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">Hardware</li>
              <li  onClick={() => {
                 scrollToTop();
                    navigate("/Software")
                  }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">Software</li>
            </ul>
          </div>
          <div className="p-[0.625rem] font-opensans">
            <div className="text-[#E6FCFF]  font-semibold 2xl:text-xl md:text-lg text-sm leading-loose mb-1">Company</div>
            <ul className="text-[#B1F6FF]  font-opensans 2xl:text-sm text-[0.6rem]">
              <li  onClick={() => {
                scrollToTop();
                navigate("/Aboutus")
                
              }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">About Us</li>
              <li onClick={() => {
                 scrollToTop();
                navigate("/Contactus")
              }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">Contact Us</li>
              <li  onClick={() => {
                 scrollToTop();
                navigate("/Careers")
              }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">Careers</li>
              {/* <li onClick={() => {
                 scrollToTop();
                navigate("/News")
              }} className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60">News</li> */}
            </ul>
        
          </div>
          </div>
          <div className="flex w-full justify-between items-center gap-10">
          <div className="pl-[0.625rem]">
          <div className="p-[0.625rem] font-opensans">
            <div className="text-[#E6FCFF]  font-semibold 2xl:text-xl md:text-lg text-sm font-opensans leading-loose mb-1">Contact Us</div>
            <ul className="text-[#B1F6FF]  font-opensans 2xl:text-sm text-[0.6rem] flex-col">
            <li className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60" onClick={handleEmailClick}>
        <div className="flex items-center justify-start gap-2.5">
          <TbMail size={18}  color="#1B99D4" />
          <span className="align-middle">info@qulabs.ai</span>
        </div>
      </li>
      <li className="mb-1 cursor-pointer hover:text-[#B1F6FF]/60" onClick={handlePhoneClick}>
        <div className="flex items-center justify-start gap-2.5">
          <IoCallOutline size={18} color="#1B99D4" />
          <span className="align-middle">+91 62816 84342</span>
        </div>
      </li>
            </ul>
            </div>
            <div className="hidden md:block">
             <div className="flex flex-row gap-3.5  md:mt-2 ">
                <div onClick={openInstagramProfile} className="flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#0C2E52] rounded-3xl cursor-pointer hover:bg-[#0C2E52]/30"><GrInstagram size={20} color="#B1F6FF" /></div>
               
                <div className=" flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#0C2E52] rounded-3xl cursor-pointer hover:bg-[#0C2E52]/30"><LuFacebook size={20} color="#B1F6FF"/></div>
               
                <div onClick={openTwitterProfile} className=" flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#0C2E52] rounded-3xl cursor-pointer hover:bg-[#0C2E52]/30"><RxTwitterLogo size={20} color="#B1F6FF"/></div>
              
                <div onClick={openLinkedInProfile} className=" flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#0C2E52] hover:bg-[#0C2E52]/30 cursor-pointer  rounded-3xl"><FiLinkedin size={20} color="#B1F6FF"/></div>
                
            </div> 
            </div>
            <div className="flex gap-1 md:mt-2 md:hidden">
                <div onClick={openInstagramProfile} className="flex items-center justify-center w-[2rem] h-[2rem] bg-[#0C2E52] rounded-3xl cursor-pointer hover:bg-[#0C2E52]/30"><GrInstagram size={15} color="#B1F6FF" /></div>
               
                <div className=" flex items-center justify-center w-[2rem] h-[2rem] bg-[#0C2E52] rounded-3xl cursor-pointer hover:bg-[#0C2E52]/30"><LuFacebook size={15} color="#B1F6FF"/></div>
               
                <div onClick={openTwitterProfile}  className=" flex items-center justify-center w-[2rem] h-[2rem] bg-[#0C2E52] rounded-3xl cursor-pointer hover:bg-[#0C2E52]/30"><RxTwitterLogo size={15} color="#B1F6FF"/></div>
              
                <div onClick={openLinkedInProfile} className=" flex items-center justify-center w-[2rem] h-[2rem] bg-[#0C2E52] hover:bg-[#0C2E52]/30 cursor-pointer  rounded-3xl"><FiLinkedin size={15} color="#B1F6FF"/></div>
                
            </div> 
            
          </div>
          </div>
          </div>
    </div>
    <div className="flex items-center justify-center w-full border-t-2 border-[#28ABE3]">
        <div className="md:w-[80%] w-[98%] border-white mt-[0.1rem] flex items-center justify-between ">
            <div className="flex w-[50%]">
                <div className="text-[#B1F6FF] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">Copyright © 2023 I</div>
                <div className="text-[#03B6CC] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">Qulabs</div>

            </div>
            <div className="flex items-center justify-end w-[50%]">
                <div className="text-[#B1F6FF] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">All Rights Reserved</div>
                <div className="text-[#03B6CC] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">| Terms and Conditions | Privacy Policy</div>

            </div>
            <div>

            </div>

        </div>

    </div>
    </div>
  );
}

